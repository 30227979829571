import { useEffect, useRef } from "react";
import proj4 from "proj4";
import { useLeafletContext } from "@react-leaflet/core";
import { useMap } from "react-leaflet";
import parseGeoraster from "georaster";
import GeoRasterLayer from "georaster-layer-for-leaflet";
import chroma from "chroma-js";

window.proj4 = proj4;

const GeotiffLayer = ({ url, minValue, maxValue}) => {
  const geoTiffLayerRef = useRef();
  const context = useLeafletContext();
  const map = useMap();
  const container = context.layerContainer || context.map;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url);
        const arrayBuffer = await response.arrayBuffer();
        const georaster = await parseGeoraster(arrayBuffer);
        const colorScale = chroma.scale(["#0000FF", "#00FFFF", "#FFFF00", "#FF0000"]).domain([minValue, maxValue]);

        const updatedOptions = {
          georaster,
          nodataValue: 0,
          pixelValuesToColorFn: (values) => {
            const value = values[0];
            if (
              value === 0
            ) {
              return "rgba(0, 0, 0, 0)";
            }
            return colorScale(value).alpha(1).css();
          },
        };
        geoTiffLayerRef.current = new GeoRasterLayer(updatedOptions);
        container.addLayer(geoTiffLayerRef.current);
        map.fitBounds(geoTiffLayerRef.current.getBounds());
      } catch (error) {
        // Handle error
      }
    };

    fetchData();

    return () => {
      geoTiffLayerRef?.current && container?.removeLayer(geoTiffLayerRef.current);
    };
  }, [url]);

  return null;
};

export default GeotiffLayer;
